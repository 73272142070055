
import {Component, Emit, Prop, PropSync, Vue} from 'vue-property-decorator';
import XDialog from '@/components/x-dialog/x-dialog.vue';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
import * as API_goods from "@/api/goods";

/**
 * 是否为批量改价：0：点击单个改价按钮 1: 点击批量改价按钮
 * */
const enum EBatchType {
  SingleButton,
  BatchButton
}

/**
 * 调价范围：0：所有商品 1：选中商品
 * */
const enum EChangeRange {
  AllGoods,
  SelectedGoods
}

/**
 * 调价类型：0：上调下调类型 1：等于类型
 * */
const enum EAdjustPriceType {
  UpDown,
  Equal
}

/**
 * 调价依据的类型：1：成本价 2：市场价
 * */
const enum EPriceType {
  CostPrice = 1,
  MktPrice
}

/**
 * 调高、调低、等于：'UP' 'DOWN' 'EQUAL'
 * */
const enum ESymolType {
  Up = 'UP',
  Down = 'DOWN',
  Eq = 'EQUAL'
}

interface ChangePriceForm {
  change_range: number,
  price_type: number,
  symbol: string,
  ratio: number,
}

@Component({
  name: 'price-changer',
  components: {
    XDialog
  }
})
export default class PriceChanger extends Vue {
  dialogProxy = $xDialog.create({
    title: '改价',
    wrapperClass: 'price-changer',
    disableConfirm: false,
    disableCancel: false,
    beforeConfirm: this.changePrice,
    width: '50vw',
  });

  @Prop({default: 1}) isBatchChangePrice;
  @PropSync('goodsIds', {type: Array, default: () => []}) goodsIdsList;
  @PropSync('goodsId', {type: Array, default: () => []}) goodsIdId;
  @Prop() advancedForm;
  @Prop() row;
  @Prop() total;

  changePriceForm: ChangePriceForm;
  adjustPriceType: number;

  constructor() {
    super();

    this.changePriceForm = {
      change_range: EChangeRange.SelectedGoods,
      price_type: EPriceType.CostPrice,
      symbol: ESymolType.Up,
      ratio: 0,
    }

    this.adjustPriceType = EAdjustPriceType.UpDown
  }

  mounted() {
    if (this.row) {
      if (this.row.symbol === "EQUAL") {
        this.adjustPriceType = 1;
        this.changePriceForm.ratio = 0;
      } else {
        this.changePriceForm.symbol = this.row.symbol ? this.row.symbol : "UP";
        this.changePriceForm.price_type = this.row.price_type ? (+this.row.price_type) : 1;
        this.changePriceForm.ratio = this.row.ratio ? (+this.row.ratio) : 0;
      }
    }
  }

  @Emit()
  onChangeComplete() {
    this.goodsIdsList = []
  }

  changePrice() {
    return new Promise<boolean>(resolve => {
      if (this.isBatchChangePrice === EBatchType.BatchButton) {
        if (this.changePriceForm.change_range === EChangeRange.SelectedGoods) {
          if (this.goodsIdsList.length === 0) {
            this.$message.error("请勾选商品");
            return;
          }
        } else {
          if (this.goodsIdsList.length === 0) {
            this.goodsIdsList.push(0);
          }
        }
      } else {
        this.changePriceForm.change_range = EChangeRange.SelectedGoods;
      }

      const obj = {
        ...this.changePriceForm,
        ...this.advancedForm,
      };
      obj.page_no = 0;
      obj.page_size = 0;
      if (this.adjustPriceType === EAdjustPriceType.Equal) {
        obj.symbol = ESymolType.Eq;
        obj.ratio = 0;
      }
      if (this.isBatchChangePrice === EBatchType.BatchButton) {
        if (!this.goodsIdsList.length) {
          throw new Error("批量改价ID缺失");
        }
      } else {
        if (!this.goodsIdId.length) {
          throw new Error("单个改价ID缺失");
        }
      }
      API_goods.batchChangePrice(this.isBatchChangePrice === EBatchType.BatchButton ? this.goodsIdsList : this.goodsIdId, obj).then(() => {
        resolve(true);
        this.onChangeComplete();
      });
    })
  }
}
