
import {Component, Prop, Vue} from 'vue-property-decorator';
import XDialog from '@/components/x-dialog/x-dialog.vue';
import XTable from '@/components/x-table/x-table.vue';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
import {$xTable, XTableProxy, XTableStdRequestParams, XTableStdResponse} from '@/components/x-table/table.proxy';
import * as API_goods from "@/api/goods";

@Component({
  name: 'dialog-audit',
  components: {
    XDialog,
    XTable
  }
})
export default class DialogAudit extends Vue {
  @Prop() goods_id;
  @Prop() log_type;

  tableProxy: XTableProxy = null!;

  status_obj = {
    0: "待审核",
    1: "审核通过",
    2: "审核不通过",
  };

  dialogProxy = $xDialog.create({
    title: '审核详情',
    wrapperClass: 'dialog-audit',
    disableConfirm: true,
    disableCancel: true,
    showClose: true,
    width: '65vw',
  });


  showDialog(goodsId, logType) {
    this.dialogProxy.display();
    this.buildTableProxy(goodsId, logType);
  }

  buildTableProxy(goodsId, logType) {
    // if (this.tableProxy) {
    //   return this.tableProxy.reset();
    // }

    this.tableProxy = $xTable.create({
      key: 'id',
      search: {
        formData: () => {
          return {
            goods_id: goodsId,
            log_type: logType
          };
        },
      },
      onRequest(params: XTableStdRequestParams): Promise<XTableStdResponse> {
        return API_goods.getChange({
          page_no: params.pageIndex,
          page_size: params.pageSize,
          ...params.search
        })
      }
    });
  }
}

