
import { Component, Vue, Watch } from 'vue-property-decorator';
import XTable from '@/components/x-table/x-table.vue';
import PriceChanger from '@/pages/goods/goods-list/components/price-changer/price-changer.vue';
import DialogAudit from '@/pages/goods/goods-list/components/dialog-audit/dialog-audit.vue';
import DialogChangePrice from '@/pages/goods/goods-list/components/dialog-change-price/dialog-change-price.vue';
import { $goodsList } from '@/pages/goods/goods-list/services/goods-list';
import { $goodsCategory } from '@/pages/goods/goods-list/services/goods-category';
import goodsclassify from "./components/GoodsClassify/goodsclassify.vue";
import goodsDetail from "@/components/GoodsDetail/GoodsDetail.vue";
import {
  $xTable,
  XTableFormValidate, XTableProxy,
  XTableStdRequestParams,
  XTableStdResponse
} from '@/components/x-table/table.proxy';
import * as API_Shop from '@/api/supplier';
import editprice_icon from "@/assets/button_icon/editprice.png";// 改价
import up_icon from "@/assets/button_icon/up.png";// 上架
import down_icon from "@/assets/button_icon/down.png";// 下架
import delete_icon from "@/assets/button_icon/delete.png";// 删除
import changelog_icon from "@/assets/button_icon/changelog.png";// 变更记录
@Component({
  name: 'goods-list',
  components: {
    XTable,
    PriceChanger,
    DialogAudit,
    DialogChangePrice,
    goodsclassify,
    goodsDetail
  },
})
export default class GoodsList extends Vue {
  editprice_icon = editprice_icon;
  up_icon = up_icon;
  down_icon = down_icon;
  delete_icon = delete_icon;
  changelog_icon = changelog_icon;
  category = $goodsCategory.create();
  goodsclassifyData: any = {};
  dialogVisible: any = false;
  keywords = {
    value: '',
    currentKey: 'goods_name',
    keys: [
      ['商品名称', 'goods_name'],
      ['商品编号', 'goods_sn'],
    ],
  };

  tableProxy: XTableProxy = null!;
  isBatchChangePrice = 0;
  reviseTaskTimer: number | undefined = undefined;
  hasReviseTask = false;// 后台是否有正在进行中的任务，如果有为false，此时不能进行改价,否则无，可以改价
  idsList: number[] = [];
  id: number[] = [];
  rowData: any = null;
  shopList: { shop_name?: string, shop_id?: string | number }[] = [];
  currentTab = '1';

  @Watch('hasReviseTask')
  onHasReviseTaskChange() {
    this.tableProxy.request();
  }

  created() {
    this.tableProxy = $xTable.create({
      key: 'id',
      search: {
        formData() {
          return {
            is_auth: "1",
            is_combo: 0,
            goods_sn: undefined,
            goods_name: undefined,
            seller_name: '',
            low_price: undefined,
            high_price: undefined,
            market_enable: '',
            category_path: undefined,
            profit_min: undefined,
            profit_max: undefined,
            lowSupprice: undefined,
            highSupprice: undefined,
            manager_profit_min: undefined,
            manager_profit_max: undefined,
            goods_source: 1,
          };
        },
        rules(ctx): { [p: string]: XTableFormValidate[] } {
          return {
            low_price: [{
              trigger: ['blur'],
              validator: (rule, value, callback) => {
                if (ctx.formData.low_price === undefined || ctx.formData.high_price === undefined) {
                  return callback();
                }

                if (ctx.formData.low_price > ctx.formData.high_price) {
                  return callback(new Error('最低供货价不可大于最高供货价'));
                }

                callback();
              }
            }],
          }
        },
        onSortChange(parentSort, prop, order) {
          const _order = order === "ascending" ? 'asc' : 'desc';
          return order ? { name: prop, order: _order } : {};
        },
      },
      onRequest: (params: XTableStdRequestParams): Promise<XTableStdResponse> => {
        this.keywords.keys.forEach(key => {
          delete params.search[key[1]];
        });

        if (this.keywords.value) {
          params.search[this.keywords.currentKey] = this.keywords.value;
        }
        if (params.search.goods_name) {
          console.log(params.search.goods_name.replaceAll(" ", "&nsbp:;"))
        }
        return $goodsList.list({
          page_no: params.pageIndex,
          page_size: params.pageSize,
          ...params.search,
          sort: params.sort.name ? `${params.sort.name}_${params.sort.order}` : 'def_asc'
        });
      }
    })
    console.log(this.tableProxy)
  }

  mounted() {
    this.loadReviseTask();
    API_Shop.getShopList({ shop_type: 2, page_no: 1, page_size: 9999 }).then(res => {
      res.data.forEach(item => {
        const obj = {}
        obj['shop_name'] = item.shop_name
        obj['shop_id'] = item.shop_id
        this.shopList.push(obj)
      });
    });
  }

  // 表头部重新渲染
  renderHeader(h, { column, $index }) {
    setTimeout(() => {
      // 获取操作按钮组的元素
      const opts = document.getElementsByClassName('operate');
      const widthArr = [] as any;
      // 取操作组的最大宽度
      Array.prototype.forEach.call(opts, function (item: any) {
        if (item.innerText || item.innerHTML) {
          widthArr.push(item.offsetWidth);
        }
      });
      // 重新设置列标题及宽度属性
      if (widthArr.length > 0) {
        column.width = Math.max(...widthArr) + 24;
        return h('span', column.label);
      } else {
        column.width = 0;
        return h('span', column.label);
      }
    }, 1)
  }

  delGoods(row) {
    this.idsList = this.tableProxy.exportData().map(item => item.id);
    if (this.idsList.length) {
      $goodsList.delGoods(this.idsList).then(() => {
        this.tableProxy.request()
        this.tableProxy.select.reset(null!, true)
      });
    } else {
      this.$message.error("请勾选商品");
    }
  }

  opengoodsclassify(row) {
    this.goodsclassifyData = {
      from: 1,
      goods_id: row.id,
      good_sn: row.goodSn,
      managerProfit: row.managerProfit
    }
    this.dialogVisible = true
  }

  tabClicked() {
    this.tableProxy.rebuildLayout!();
    this.tableProxy.reset(true);
    this.tableProxy.search.formData.is_auth = "1";
    this.tableProxy.search.formData.is_combo = 0;
    this.tableProxy.search.formData.goods_source = this.currentTab;
    this.keywords.value = '';
    this.tableProxy.request();
    this.$forceUpdate();
  }

  beforeDestroy() {
    clearInterval(this.reviseTaskTimer);
    this.reviseTaskTimer = undefined;
  }

  loadReviseTask() {
    // 判断是否有改价任务 OK没有任务可以改价 NO有任务不能改价
    this.reviseTaskTimer = setInterval(() => {
      $goodsList.hasReviseTask().then(res => {
        if (res) {
          this.hasReviseTask = false;
          clearInterval(this.reviseTaskTimer);
          // 任务改价完成后重新获取一些列表，更新一下改价完成后的列表价格利率
          // this.GET_GoodsList();
        } else {
          this.hasReviseTask = true;
        }
      });
    }, 1000);
  }

  changeComplete() {
    this.tableProxy.request();
    this.loadReviseTask();
    // (this.$refs.priceChanger as any).dialogProxy.display();
  }

  /**
   *  批量上架、下架
   * */
  batchOperateGoods(type) {
    this.idsList = this.tableProxy.exportData().map(item => item.id);
    if (type === 1) {
      if (this.idsList.length) {
        $goodsList.batchUp(this.idsList).then(() => {
          this.tableProxy.request()
          this.tableProxy.select.reset(null!, true)
        });
      } else {
        this.$message.error("请勾选商品");
      }
    } else if (type === 2) {
      if (this.idsList.length) {
        $goodsList.batchUnder(this.idsList).then(() => {
          this.tableProxy.request()
          this.tableProxy.select.reset(null!, true)
        });
      } else {
        this.$message.error("请勾选商品");
      }
    }
  }

  /**
   * 查看审核记录
   */
  checkAudit(val, type) {
    (this.$refs.dialogAudit as any).showDialog(val, type);
  }

  /**
   * 查看变更记录
   */
  checkChange(val, type) {
    (this.$refs.dialogChangePrice as any).showDialog(val, type);
  }

  /** 选择框改变 */
  handleSelectionChange(val) {
    this.idsList = val.map(i => i.id)
  }

  /**
   * 批量改价
   */
  handleGoodsPriceChangeBatch() {
    this.isBatchChangePrice = 1;
    this.rowData = null;
    this.idsList = this.tableProxy.exportData().map(item => item.id);
    (this.$refs.priceChanger as any).dialogProxy.display();
  }

  /**
   * 单个改价
   */
  handleGoodsPriceChange(row) {
    this.id = [row.id];
    this.isBatchChangePrice = 2;
    this.rowData = row;
    (this.$refs.priceChanger as any).dialogProxy.display();
  }
}
