import * as $GoodsAPI from "@/api/goods";
import { unixToDate } from "../../../../../ui-utils/Foundation";

const { $confirm, $message, $prompt } = window["app"];

export interface ListGoodsApiParams {
  page_no?: number;
  page_size?: number;
  goods_sn?: string;
  goods_name?: string;
  seller_name?: string;
  low_price?: number; // 最低销售价
  high_price?: number; // 最高销售价
  market_enable?: string;
  category_path?: string; // 分类路径
  profit_min?: number;
  profit_max?: number;
  manager_profit_min?: number;
  manager_profit_max?: number;
  sort?: string;
}

export class GoodsItemProxy {
  id: number;
  goodSn: string; // 商品编码
  thumbnail: string; // 封面
  goodsName: string; // 商品名称
  specsName: string; // 规格名称
  cost: number; // 成本价
  price: number; // 销售价
  mktprice: number; // 市场价
  managerProfit: number; // 利润率
  platformProfit: number; // 毛利率
  cat_name: string; // 分类名称
  quantity: number; // 库存
  buyCount: number; // 总销量
  sellerName: string; // 供应商
  authStatus: number; // 审核状态
  authStatusText: string; // 审核状态文本
  saleStatus: number; // 在售状态
  isOnSale: boolean;
  saleStatusText: string; // 在售状态文本
  createTime: number; // 创建时间

  constructor(public raw: any) {
    this.id = raw.goods_id;
    this.goodSn = raw.good_sn;
    this.thumbnail = raw.thumbnail;
    this.goodsName = raw.goods_name;
    this.specsName = raw.specs;
    this.cost = raw.cost;
    this.price = raw.price;
    this.mktprice = raw.mktprice;
    this.managerProfit = raw.manager_profit;
    this.platformProfit = raw.platform_profit;
    this.cat_name = raw.cat_name;
    this.quantity = raw.quantity;
    this.buyCount = raw.buy_count;
    this.sellerName = raw.shop_name;
    this.authStatus = raw.is_auth;
    this.authStatusText = (() => {
      switch (this.authStatus) {
        case 0:
          return "审核中";
        case 1:
          return "审核通过";
        case 2:
          return "审核拒绝";
        default:
          return "";
      }
    })();
    this.saleStatus = raw.market_enable;
    this.isOnSale = raw.market_enable === 1;
    this.saleStatusText = this.isOnSale ? "已上架" : "已下架";
    this.createTime = unixToDate(
      raw.up_time ? raw.up_time : "",
      "yyyy-MM-dd hh:mm"
    );
  }

  delGood() {
    return new Promise<any>((resolve) => {
      $confirm("确定要删除这个商品吗？", "提示", {
        type: "warning",
      }).then(() => {
        $GoodsAPI.deletePartyGoods(this.id).then(() => {
          $message.success("删除商品成功！");
          resolve(true);
        });
      });
    });
  }

  switchShelf() {
    if (!this.isOnSale) {
      return new Promise<any>((resolve) => {
        $confirm("确定要上架这个商品吗？", "提示", {
          type: "warning",
        }).then(() => {
          $GoodsAPI.upGoods(this.id).then(() => {
            $message.success("上架商品成功！");
            resolve(true);
          });
        });
      });
    } else {
      return new Promise<any>((resolve) => {
        $prompt("请输入下架原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          $GoodsAPI.underGoods(this.id, value).then(() => {
            $message.success("下架商品成功！");
            resolve(true);
          });
        });
      });
    }
  }
}

class GoodsListService {
  list(params: ListGoodsApiParams) {
    return $GoodsAPI.getGoodsList(params).then((resp) => {
      resp.data = resp.data.map((d) => new GoodsItemProxy(d));
      console.log(resp.data);

      return resp;
    });
  }

  hasReviseTask() {
    return $GoodsAPI.hasReviseTask();
  }

  delGoods(params: any) {
    return new Promise<any>((resolve) => {
      $confirm("确定要批量删除这些商品吗？", "提示", {
        type: "warning",
      }).then(() => {
        $GoodsAPI.deletePartyGoods(params).then(() => {
          $message.success("批量删除商品成功！");
          resolve(true);
        });
      });
    });
  }

  batchUp(params: any) {
    return new Promise<any>((resolve) => {
      $confirm("确定要批量上架吗？", "提示", {
        type: "warning",
      }).then(() => {
        $GoodsAPI.upGoods(params).then(() => {
          $message.success("批量上架商品成功！");
          resolve(true);
        });
      });
    });
  }

  batchUnder(params: any) {
    return new Promise<any>((resolve) => {
      $prompt("请输入下架原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        $GoodsAPI.underGoods(params, value).then(() => {
          $message.success("批量下架商品成功！");
          resolve(true);
        });
      });
    });
  }
}

export const $goodsList = new GoodsListService();
